import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import BlogListEntry from '../components/blog-list-entry';
import PageHeader from '../components/page-header';

const TagPage = ({data}) => (
    <Layout>
        <PageHeader
            heading={`Tag: ${data.wordpressTag.slug}`}
            subHeading={`All posts tagged with "${data.wordpressTag.slug}"`}
            pageTitle={data.wordpressTag.yoast_meta.yoast_wpseo_title}
            seoMetaData={data.wordpressTag.yoast_meta}
        />
        <div className="blog-listing-component">
            <div className="component-wrapper">
                {
                    ! data.allWordpressPost ?
                    (<h3>Unfortunately we cannot locate requested data.</h3>) :
                    data.allWordpressPost.edges.map(({node}) => (
                        <BlogListEntry post={node} key={node.slug} />
                    ))
                }
            </div>
        </div>
    </Layout>
);

export default TagPage;

export const pageQuery = graphql`
    query($id: String!) {
        allWordpressPost(filter: {tags: {elemMatch: { id: {eq: $id}}}}) {
            edges {
                node {
                    title
                    slug
                    excerpt
                    date(formatString: "Do [of] MMMM, YYYY")
                    author {
                        link
                        name
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                original {
                                    src
                                }
                            }
                        }
                    }
                    acf {
                        featured_image_background_color
                    }
                }
            }
        }
        wordpressTag(id: {eq: $id}) {
            slug
            yoast_meta {
                yoast_wpseo_title
            }
        }
    }
`;
